(function ($) {
    var leftVideoBlock = jQuery(".video-container-inner-left");
    var rightVideoBlock = jQuery(".video-container-inner-right");
    var videoModal = jQuery(".homepage-video-lightbox");
    var videoModalOpen = jQuery(".play-video-container");
    var videoModalClose = jQuery("#video-modal-close");
    var matchHeight = function() {
        leftVideoBlock.css('height', rightVideoBlock.outerHeight());
    };

    // Checks if element is in viewport
    $.fn.isInViewport = function() {
        if ($(this).length !== 0) {
            console.log($(this));
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
            return elementBottom > viewportTop && elementTop < viewportBottom;
        }
    };

    // Match height if blocks are stacked
    if (jQuery(window).width() < 768) {
        matchHeight();
    }

    // Check if gif is in view, then play gif
    jQuery(window).scroll(function() {
        if ($(".video-container-inner-left").isInViewport()) {
            setTimeout(function () {
                jQuery(".video-container-inner-left").removeClass("video-section-left-bg");
                jQuery(".video-container-inner-left").addClass("video-section-left-bg-gif");
            }, 400);
        }
    });

    // Resize gif height on screen resize
    jQuery(window).on('resize', function () {
        matchHeight();
    });

    // Open modal
    videoModalOpen.click(function () {
        videoModal.addClass("modal-active");
    });

    // Close modal
    videoModalClose.click(function () {
        videoModal.removeClass("modal-active");
    });

    var owlh = $('.owl-carousel');

    jQuery('.customNextBtn').click(function() {
        owlh.trigger('next.owl.carousel');
    });

    jQuery(".owl-carousel").owlCarousel({
        loop: true,
        nav: true,
        navText: ["",""],
        dots: true,
        autoplay: false,
        items: 1,
        responsive: {
          0: {
            nav: false,
          },
          576: {
            nav: false,
          },
          768: {
            nav: true,
          }
        }
      });

})(jQuery);

